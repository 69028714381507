import TopicCard from "src/components/TopicCard";
import { Card } from "src/components/TopicCard/styled";
import { device } from "src/helpers/responsive";
import styled from "styled-components";

export const Container = styled.div``;

export const SwipePageContentWrapper = styled.div`
  min-height: 700px;
  @media ${device.tabletSm} {
    margin-left: clamp(1.5rem, -7.6667rem + 24.4444vw, 7rem);
    margin-right: clamp(1.5rem, -7.6667rem + 24.4444vw, 7rem);
  }
`;

export const ShareWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: space-between;
  @media ${device.tabletSm} {
    justify-content: unset;
  }
`;
export const Title = styled.div`
  font-weight: 700;
  color: ${props => props.theme.primary};
  font-size: clamp(1.5rem, 0.9643rem + 2.6786vw, 2.25rem);
  @media ${device.tabletSm} {
    line-height: 45px;
  }
`;

export const ContentItem = styled.div`
  /* margin-bottom: 10px; */
`;

export const NoContentToView = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: ${props => props.theme.primary};
`;

export const Image = styled.img`
  width: 100%;
  max-height: 444px;
  object-fit: contain;
`;

export const RelatedArticleText = styled.div`
  font-size: 17px;
  color: ${props => props.theme.primary};
  font-weight: 500;
  margin-bottom: 15px;
  margin-left: 0px;
`;

export const Topic = styled(TopicCard)`
  width: 150px;
  margin: 10px 10px 20px 10px;

  &.active {
    pointer-events: none;
    cursor: auto;

    ${Card} {
      box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.25);
      &:after {
        content: "";
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 5px;
      }
    }
  }

  &:not(.active) {
    ${Card} {
      box-shadow: none;
    }
  }
`;

export const HTMLNodeWrapper = styled.div`
  all: unset;
`;
export const HTMLNode = styled.div`
  word-break: break-word;
  font-family: Arial, sans-serif, Roboto;
  font-size: 14px;
  line-height: 1.4;
  margin-top: 0;
  color: #212529;

  h1 {
    font-weight: bold;
    font-size: 24px;
    font-family: "Open Sans";
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
  h2 {
    font-size: 20px;
    font-family: "Open Sans";
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
  h3 {
    font-size: 16px;
    font-family: "Open Sans";
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
  h4 {
    font-size: 14px;
    font-family: "Open Sans";
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
  p {
    font-size: 16px;
    font-style: normal;
    margin-bottom: 1rem;
  }
  img {
    width: 100% !important;
    object-fit: contain;
  }
`;

export const Image360Wrapper = styled.div`
  position: relative;
`;

export const Image360FullScreenButton = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  color: #222;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  padding: 5px;
  cursor: pointer;
  z-index: 1;
`;

export const Image360Full = styled.div`
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
`;

export const ImageInnerShadow = styled.div`
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 20px;
  top: 20px;
`;

export const Link = styled.a`
  display: block;
  position: relative;
  &:active {
    box-shadow: none;
    ${ImageInnerShadow} {
      box-shadow: inset 0px 0px 20px 10px rgba(0, 0, 0, 0.35);
      opacity: 0.4;
    }
  }
`;
export const LinkImage = styled.img`
  max-width: 287px;
  width: 100%;
  object-fit: cover;
  box-shadow: 0px 1px 4px rgba(24, 27, 32, 0.2);

  &:hover {
    box-shadow: 0px 4px 8px rgba(24, 27, 32, 0.2);
  }
  @media ${device.tabletSmAsMax} {
    max-width: 100%;
    width: 100%;
  }
`;

export const LinkButton = styled.button`
  max-width: 287px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  height: 48px;
  text-overflow: ellipsis;
  background: ${props => props.theme.buttonBackground};
  color: ${props => props.theme.buttonText};
  font-weight: 500;
  padding: 8px 32px;
  border-radius: 4px;
  box-shadow: 0px 1px 16px rgba(24, 27, 32, 0.2);
  border: none;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 16px rgba(24, 27, 32, 0.2);
  }
  &:active {
    opacity: 0.9;
  }
  @media ${device.tabletSmAsMax} {
    max-width: 100%;
    width: 100%;
  }
`;

export const TabContainer = styled.div`
  min-height: 42px;
`;

export const LinkButtonWrapper = styled.div`
  text-align: center;
  &:active ${LinkButton} {
    box-shadow: inset 0px 0px 5px 5px rgba(0, 0, 0, 0.35);
    opacity: 0.4;
  }
`;

export const HTMLContentWrapper = styled.div`
  padding: 0px 10px;
  margin-block: 18px;
  lineheight: 1.4;
  color: #212529;
`;

export const MediaContentWrapper = styled.div`
  @media ${device.tabletSm} {
    margin-block: 18px;
    padding: 0px 10px;
  }
`;

export const TabPageListWrapper = styled.div`
  margin-block: 15px;
  display: block;
  @media ${device.desktop} {
    display: none;
  }
`;
